<template>
  <div>
    <App
      :title="chooseProfile ? 'customer-profile' : chooseCloseLost ? 'Reason of Closed lost' : 'Prospects Follow-up'"
      @click-left="onNavBarClickLeft"
      :left-arrow="!chooseProfile && !chooseCloseLost"
      v-if="isshow"
    >
      <Tabs
        v-model="currTab"
        class="tabs full-container"
        @change="changeTabs"
      >
        <Tab
          v-for="$e in tabDataList"
          :key="$e.label"
          :name="$e.label"
          :title="$e.title"
          :disabled="$e.disabled"
        >
          <DynamicForm
            ref="form"
            v-model="formData"
            :rules="rules"
            :fieldData="fieldData"
          >
          </DynamicForm>
          <!-- <Field
            :value="((profileData && profileData.length) || detailCprofile) ? '已选择' : ''"
            label-width="120"
            label="Customer Profile"
            readonly
            clickable
            @click="chooseProfile = true"
            input-align="right"
            placeholder="请选择"
            right-icon="arrow"
          /> -->
        </Tab>
      </Tabs>
      <div :style="chooseProfile ? 'display:block' : 'display:none'">
        <CustomerProfile
          @on-cancel="cancelProfile"
          @on-confirm="confirmProfile"
          :detailCprofile="detailCprofile"
          :remark="remark"
          :data="customerprofileList"
          :show="chooseProfile"
        ></CustomerProfile>
      </div>
      <div v-if="chooseCloseLost">
        <CloseLost
          class="close-lost"
          @on-cancel="cancelCloseLost"
          @on-confirm="confirmCloseLost"
          :data="closeLostData"
          :show="chooseCloseLost"
        ></CloseLost>
      </div>
      <FooterBtn
        @cancel='onNavBarClickLeft'
        @ok='onNavBarClickRight'
      ></FooterBtn>
    </App>
    <div v-else>
      <OppfollowupScuess />
    </div>
  </div>
</template>  

<script>
import { Tab, Tabs, Toast, Field } from 'vant'
import DynamicForm from '_c/dynamicForm'
import FooterBtn from '_c/footerBtn/index'
import { getList, oppFollowUp, getOppInfo } from '@/api/chargequery'
import { customerprofile } from '@/api/common'
import CustomerProfile from '_c/customer-profile/index'
import CloseLost from './close-lost/index'
import OppfollowupScuess from './oppfollowupScuess'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import './index.less'
import dayjs from 'dayjs'

export default {
  name: 'businessOpportunitiesFollowUpFrom',
  components: {
    Tab,
    Tabs,
    DynamicForm,
    CustomerProfile,
    CloseLost,
    OppfollowupScuess,
    FooterBtn,
    // Field
  },
  data () {
    return {
      currTab: '',
      tabDataList: [
        { title: 'Open', label: 'OPEN', disabled: false },
        { title: 'TD', label: 'TD', disabled: false },
        { title: 'Follow Up', label: 'FOLLOW_UP', disabled: false },
        { title: 'Long Term', label: 'LONG_TERM', disabled: false },
        { title: 'Close-Lost', label: 'CLOSE_LOST', disabled: false },
        { title: 'Close-Duplicate', label: 'CLOSE_DUPLICATE', disabled: false },
        // { title: '意向金', label: 'DEPOSIT_RESERVED', disabled: false },
        // { title: '大定已下', label: 'ORDER', disabled: false },
        // { title: '已交付', label: 'DELIVERED', disabled: false },
        // { title: '已退订', label: 'CLOSE_CANCELLED', disabled: false }
      ],
      formData: {
        profile: '',
        reason: '',
        cprofile: '',
        followupForm: '',
        score: '',
        campaignCode: ''
      },
      isshow: true,
      nextshow: true,
      rules: {
        score: [
          { required: true, message: '请选择' }
        ],
        followupForm: [
          { required: true, message: 'Please select a follow-up method.' }
        ],
        campaignCode: [
          { required: true, message: 'Please select a campaign.' }
        ],
        followupMessage: [
          { required: true, message: '请输入跟进信息' }
        ],
        nextFollowupTime: [
          { required: true, message: '请选择下次跟进时间' }
        ],
        reason: [
          { required: true, message: '请输入原因' }
        ],
        isToshop: [
          { required: true, message: 'Please select if customer visited the outlet.' }
        ]
      },
      fieldData: [],
      chooseProfile: false,
      customerProfileCheck1: {},
      stateCode: '',//status  ----默认传来的状态
      Info: '', //info
      typea: '', // 判断活动信息是否展示
      fail: [],//
      other: '',//暂败其他
      profileData: [],
      chooseCloseLost: false,
      closeLostData: {
        list: [],
        reason: ''
      },
      customerprofileList: [],
      isshop: false,
      detailCprofile: [],
      remark: ''
    }
  },
  computed: {
    sourceData () {
      return [
        {
          title: 'Follow-up',
          data: [
            { label: 'Follow-up Method', key: 'followupForm', type: 'select', options: this.upform() },
            { label: '活动信息', key: 'campaignCode', type: 'select', options: this.list, visible: this.typea === '10' },//
            { label: 'Follow-up Details', key: 'followupMessage', direction: 'column', props: { placeholder: 'Please write', type: 'textarea', border: true } },
            { label: 'Next Follow-up Date', key: 'nextFollowupTime', type: 'calendar', attrs: { 'min-date': new Date() }, visible: this.nextshow },
          ]
        },
        {
          title: !this.isshop ? '' : 'Customer Profile',
          data: [
            { label: 'If Visited', key: 'isToshop', type: 'select', options: this.istoshopOptions(), visible: this.isshop },
            // { label: 'Customer Profile', key: 'profile', props: { placeholder: '请选择', clickable: true, readonly: true, 'right-icon': 'arrow' }, listeners: { click: this.profileShow } },
          ]
        }
      ]
    }
  },
  watch: {
    'formData.followupForm': {
      handler (e) {
        this.fieldData.forEach(item => {
          if (item.title === 'Follow-up') {
            item.data.forEach(el => {
              if (el.label === "活动信息") {
                if (e == 4) {
                  el.visible = true
                } else {
                  el.visible = false
                }
              }
            })
          }
        })
      }
    },
    immediate: true,
    deep: true
  },
  methods: {
    baseData () {
      return this.$storage.get('baseData')
    },
    // 跟进形式
    upform () {
      return this.baseData().follow_from.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 是否到店
    istoshopOptions () {
      return this.baseData().is_to_shop.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 获取活动信息
    async getList () {
      const res = await getList()
      if (res.code === '000000') {
        if (res.data.records) {
          this.list = res.data.records.map(item => {
            return { 'label': item.name, 'value': item.code }
          })
        }
        this.init()
        // return res.data.records
      } else {
        Toast(res.msg)
      }
    },
    // 返回
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    async onNavBarClickRight () {
      this.$refs['form'][0].validate().then(async (valid) => {
        if (valid) {
          Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay: true
          })
          const data = this.formatData(this.formData)
          const res = await oppFollowUp(data)
          Toast.clear()
          if (res.code === '000000') {
            this.isshow = false
          } else {
            Toast(res.msg)
          }
        }
      })
    },
    formatData (data) {
      const res = {}
      // 信息
      if (this.stateCode === 'CLOSE_LOST') {
        res.closeLostReason = data.closeLostReason
        // 暂败原因
        if (data.closeLostReason.includes('9')) {
          data.closeLostReason.push(data.reason)
        }
      } else if (this.stateCode === 'CLOSE_DUPLICATE') {
        // 重复原因
        res.reason = data.reason
      }
      if (data.closedate) {
        res.closedate = dayjs(data.closedate).format('YYYY-MM-DD') // dayjs().valueOf( data.closedate )
      }
      // CP
      if (this.profileData) {
        const profile = this.customerprofileList.map(item => {
          return {
            name: item.name,
            code: item.code,
            type: item.type,
            trees: item.trees.filter(t => this.profileData.includes(t.code))
          }
        })
        res.cprofile = profile
      }
      // 备注信息
      if (this.remark) {
        res.remark = this.remark
      }
      // 跟进形式
      res.followupForm = data.followupForm
      // 跟进信息
      res.followupMessage = data.followupMessage
      // 活动code
      if (data.campaignCode && data.followupForm === '4') {
        res.campaignCode = data.campaignCode // campaignCode
      }
      // 下次跟进时间
      if (data['nextFollowupTime']) {
        res.nextFollowupTime = dayjs(data['nextFollowupTime']).format('YYYY-MM-DD')
      }
      // 是否到店
      data.isToshop ? res.isToshop = data.isToshop : res.isToshop = true
      // status
      res.status = this.stateCode
      // 线索ID
      res.oppid = this.detail.eosOppId
      // ownerUserId
      res.ownerUserId = this.detail.eosOwnerUserId
      return res
    },
    changeTabs (name) {
      this.stateCode = name
      this.formData = {}
      // this.init()
      if (name === 'CLOSE_LOST' || name === 'LONG_TERM' || name === 'CLOSE_DUPLICATE') {
        this.nextshow = false
      } else {
        this.nextshow = true
      }
      this.init()
      if (name === 'FOLLOW_UP' || name === 'DEPOSIT_RESERVED') {
        this.fieldData.unshift({
          title: '',
          data: [
            { label: 'Close Date', key: 'closedate', type: 'calendar', attrs: { 'min-date': new Date(), 'max-date': dayjs().add(13, 'day').toDate() } },
          ]
        })
      }
      if (name === 'CLOSE_LOST') {
        this.fieldData.unshift({
          title: '',
          data: [
            { label: 'Reason of Closed lost', key: 'TPreason', props: { placeholder: 'Please Select', clickable: true, readonly: true, 'right-icon': 'arrow', maxLength: 200 }, listeners: { click: this.lostShow } },
          ]
        })
        this.rules['TPreason'] = [{ require: true, message: 'Please Select' }]
      }
      if (name === 'CLOSE_DUPLICATE') {
        this.fieldData.unshift({
          title: '',
          data: [
            { label: 'Reason of duplication', key: 'reason', direction: 'column', props: { placeholder: 'Please Select', type: 'textarea', border: true, maxLength: 200 } },
          ]
        })
      }
    },
    // 获取 Customer Profile 主数据
    getCustomerprofile () {
      return customerprofile().then(res => {
        if (res.success) {
          this.customerprofileList = JSON.parse(res.data.template).map(item => {
            return {
              ...item,
              options: item.trees.map(it => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
        }
      })
    },
    // Customer Profile
    profileShow () {
      this.chooseProfile = true
    },
    confirmProfile (data) {
      this.profileData = Object.keys(data).map(el => { return data[el] }).toString().split(',')
      this.remark = data.remark
    },
    cancelProfile () {
      this.chooseProfile = false
    },
    // 暂败原因
    lostShow () {
      this.chooseCloseLost = true
    },
    cancelCloseLost () {
      this.chooseCloseLost = false
    },
    // 暂败原因
    confirmCloseLost (data) {
      this.formData.reason = data.reason
      this.closeLostData = data
      if (data.list.length > 0) this.formData = Object.assign(this.formData, { closeLostReason: data.list, reason: data.reason })
      this.fieldData.forEach(item => {
        item.data.forEach(el => {
          if (el.props.label === 'Reason of Closed lost') {
            if (data.list.length > 0) {
              el.props.placeholder = `已选择${data.list.length}项`
            } else {
              el.props.placeholder = '请选择'
            }
          }
        })
      })
    },
    init () {
      this.fieldData = JSON.parse(JSON.stringify(this.sourceData))
      this.fieldData[0].data[3].attrs['min-date'] = new Date()
      // this.fieldData[1].data[1].listeners = { click: this.profileShow }
    },
    // 初始化
    async getData () {
      await this.getList()
      await this.getOppInfo()
      this.detail.istoshop ? this.isshop = false : this.isshop = true
      this.sourceData.forEach(item => {
        if (item.title === 'Customer Profile') {
          item.data.forEach(el => {
            if (el.label === 'If Visited') {
              el.visible = this.isshop
            }
          })
        }
      })
      this.detail.status ? this.detail.status : (this.$route.query.orderFollowup === 'ORDER' ? this.detail.status = 'ORDER' : null)
      this.stateFunc(this.detail.status)
    },
    // 获取线索详情
    async getOppInfo () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const params = {
        id: this.$route.query.eosOppId
      }
      const res = await getOppInfo(params)
      Toast.clear()
      if (res.code === '000000') {
        this.detail = res.data
        this.detailCprofile = JSON.parse(res.data.cprofile)
        this.remark = res.data.remark
        if (this.detail.status === 'ATTEMPTING_TOUCHING') {
          this.stateCode = 'IN_TOUCHING'
        } else {
          this.stateCode = this.detail.status
        }
      } else {
        Toast(res.msg)
      }
    },
    stateFunc (state) {
      if (state === 'OPEN' || state === 'TD' || state === 'FOLLOW_UP' || state === 'LONG_TERM' || state === 'CLOSE_LOST' || state === 'CLOSE_DUPLICATE' || state === 'CLOSE_CANCELLED') this.currTab = state //跳转的值status。
      else if (state === 'DEPOSIT_RESERVED' || state === 'ORDER') this.currTab = state
      const dataList = this.tabDataList.map(e => {
        if (state === 'DEPOSIT_RESERVED' || state === 'ORDER' || state === 'DELIVERED' || state === 'CLOSE_CANCELLED') {
          if (e.label === state) e.disabled = false
          else e.disabled = true
        }
        if (state === 'OPEN' || state === 'TD' || state === 'FOLLOW_UP' || state === 'LONG_TERM' || state === 'CLOSE_LOST' || state === 'CLOSE_DUPLICATE') {
          if (e.label === 'FOLLOW_UP' || e.label === 'LONG_TERM' || e.label === 'CLOSE_LOST' || e.label === 'CLOSE_DUPLICATE') e.disabled = false
          else e.disabled = true
        }
        if (state === 'TD' && e.label === 'TD') e.disabled = false
        // if (state === 'OPEN' && e.label === 'OPEN') e.disabled = false
        return e
      })
      this.tabDataList = dataList
      this.changeTabs(this.currTab)
    },
    clickInfo () {
      //
    }
  },
  mounted () {
    this.getData()
    this.getCustomerprofile()
  }
}
</script>
