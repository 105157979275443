<template>
  <!-- <App title="暂败原因" left-arrow @click-left="onNavBarClickLeft" right-text="确定" @click-right="onNavBarClickRight"> -->
  <div class="cp-box">
    <CellGroup>
      <Field label=""
             label-width="100%"
             class="option">
        <template #input>
          <div class="labels">
            <button type="button"
                    @click="changeData($i)"
                    v-for="$i in datalist"
                    :key="$i.key"
                    :class="$i.active? 'on' : ''">
              {{$i.label}}
            </button>
          </div>
        </template>
      </Field>
      <Field v-if="selectData.list.includes('9')"
             v-model="selectData.reason"
             type="textarea"
             label=""
             label-width="100%"
             placeholder="Please describe the reason."
             class="reason" />
    </CellGroup>
    <FooterBtn @cancel="onNavBarClickLeft"
               @ok="onNavBarClickRight"
        cancelText="Back"
        okText="Submit"></FooterBtn>
  </div>

  <!-- </App> -->
</template>

<script>
import { CellGroup, Field, Toast } from 'vant'
import FooterBtn from '_c/footerBtn/index'
import 'vant/lib/cell-group/style'
import 'vant/lib/field/style'
import './index.less';

export default {
  name: 'closeLost',
  components: { CellGroup, Field, FooterBtn },
  props: {
    data: {
      type: Object,
      default () {
        return {
          list: [],
          reason: ''
        }
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: {
      handler (value) {
        if (value) {
          this.changeData()
        }
      },
      deep: true
    },
    'selectData.reason': {
      handler (e) {
        if (e.length > 200) {
          Toast('长度不得超过200')
          this.selectData.reason = ''
        }
      }
    }
  },
  data () {
    return {
      datalist: [
        // { key: '0', label: '等不及购买其他品牌', active: false },
        // { key: '1', label: '对产品不满意', active: false },
        // { key: '2', label: '对新产品不信任', active: false },
        // { key: '3', label: '资金不足', active: false },
        // { key: '4', label: '没有牌照或指标', active: false },
        // { key: '5', label: '担心异地售后无保障', active: false },
        // { key: '6', label: '不能安装充电桩', active: false },
        // { key: '7', label: '购买其他车型', active: false },
        // { key: '8', label: '提车日期晚', active: false },
        // { key: '9', label: '其他', active: false }
      ],
      selectData: {
        list: [],
        reason: ''
      }
    }
  },
  methods: {
    onNavBarClickLeft () {
      this.$emit('on-cancel')
    },
    onNavBarClickRight () {
      this.$emit('on-confirm', this.selectData)
      this.$emit('on-cancel')
    },
    changeData (item) {
      const lostreson = this.$storage.get('baseData').close_lost_reason
      this.datalist = lostreson
      this.datalist.forEach(item => {
        item.label = item.key
        item.active = item.deleted
      })
      if (item && item.value) {
        if (this.selectData.list.includes(item.value)) {
          this.selectData.list.splice(this.selectData.list.indexOf(item.value), 1)
          item.active = false
        } else {
          this.selectData.list.push(item.value)
          item.active = true
        }
      }
      this.datalist.forEach((e) => {
        if (this.selectData.list.includes(e.value)) e.active = true
      })
      console.log(this.selectData.list)
    },
  },
  created () {
    this.selectData = JSON.parse(JSON.stringify(this.data))
    this.changeData()
  }
}
</script>
